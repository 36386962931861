<template>
  <div class="wrap">
    <van-nav-bar :title="$t('newAdd.newNavTitle')">
      <template #left>
        <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
      </template>
      <template #right>
        <div class="bar" @click="$router.push('/recharge-list')">
          <i class="iconfont icon-lishijilu"></i>
        </div>
      </template>
    </van-nav-bar>
    <div class="userinfo_bar">
      <!-- <div class="nav_title">{{ $t("newAdd.newNavTitle") }}</div> -->
      <VanImage width="70" height="70" :src="$img(userInfo.userimg)" round>
        <VanImage slot="error" width="70" height="70" :src="require('@/assets/Img/myImg/MyOne.png')" round />
      </VanImage>
      <div class="level_bar">
        <div class="nickname">
          {{
      userInfo.username ? userInfo.username : $t("newAdd.noLoginUserName")
    }}
        </div>
        <div class="hr"></div>
        <div class="level">{{ userInfo.rank_title }}</div>
      </div>
      <!-- <div class="record_bar" @click="$router.push('/ThirdRechargeList')">
        <i class="iconfont icon-lishijilu"></i>
      </div> -->
      <div class="tradeinfo_bar">
        <div class="id_bar">
          <div class="title">ID</div>
          <div class="value">{{ userInfo.id }}</div>
        </div>
        <div class="balance">
          <div class="title">{{ $t("Home.WithdrawSu.Balance") }}</div>
          <div class="value">
            {{ $money(Number(userInfo.money).toFixed(2) || "0.00", false) }}
          </div>
        </div>
      </div>
    </div>
    <div class="form_bar">
      <div class="submission_bar" :style="theme == 'light' ? { backgroundColor: 'var(--nav-background)' } : {}">
        <div class="title">{{ $t("newAdd.actualMoney") }}</div>
        <div class="input">
          <input type="number" v-model="newForm.money" />
        </div>
      </div>
      <div class="type_bar" v-if="!verConfig.banRechargeType">
        <div class="items" v-for="(item, index) in useNetWorkList" :key="index"
          :class="index === useNetWorkActive ? 'active' : ''"
          :style="(index == useNetWorkActive && theme == 'light') ? { color: 'var(--btnsizeColor)' } : {}"
          @click="handleTabItemClick(item, index)">
          {{ item.title }}
        </div>
      </div>
      <div class="textarea_wrap" :style="verConfig.banRechargeType && { marginTop: '15px' }">
        <textarea name="" id="" cols="30" rows="10" class="hash_bar" :placeholder="$t('hashPlaceholder')"
          v-model="newForm.hash"></textarea>
        <div class="btn_past" @click="handleCopy(newForm.hash)">
          {{ $t("newAdd.paste") }}
        </div>
      </div>
    </div>
    <div class="submit_bar" @click="handleCommit">
      {{ $t("newAdd.commitBtnText") }}
    </div>
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from "@/api";
import Cookies from "js-cookie";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import verConfig from "@/utils/verConfig";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
  Button,
  Image as VanImage,
} from "vant";
import { mapGetters } from 'vuex';

export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    Tabbar,
    VanImage,
  },
  data() {
    return {
      verConfig,
      countList: [
        "100",
        "200",
        "500",
        "1000",
        "5000",
        "10000",
        "30000",
        "50000",
      ],
      // account: "",
      number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      goodInfo: {},
      rechargeType: "",
      // userInfo: {},
      useNetWorkList: [],
      useNetWorkActive: 0,
      newForm: {
        money: 0,
        netWork: "",
        hash: "",
      },
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      isTabBarLinkNotShowShopMoney: false,
    };
  },
  created() {
    getUserInfoNew();
    this.getRechargeList();
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    // this.userInfo = getUserInfo();
    // this.account = getUserInfo().userphone || getUserInfo().email;
    const data = localStorage.getItem("rechargeGoodDataInfo")
      ? JSON.parse(localStorage.getItem("rechargeGoodDataInfo"))
      : this.$route.query;
    // this.goodInfo = JSON.parse(this.$route.query.data);
    this.goodInfo =
      typeof data.data === "string" ? JSON.parse(data.data) : data.data;
    this.rechargeType = data.type;
    if (this.goodInfo) {
      this.number = this.goodInfo.goods_price;
      this.isTabBarLinkNotShowShopMoney = true;
    }
  },
  methods: {
    handleTabItemClick(item, index) {
      if (this.useNetWorkActive === index) return;
      this.useNetWorkActive = index;
      this.newForm.netWork = item.title;
    },
    handleCommit() {
      const { hash, money } = this.newForm;
      if (!hash) return Toast(this.$t("newAdd.commitHint"));
      this.paySubmit(this.useNetWorkList[this.useNetWorkActive]);
    },
    handleUseNetWorkChange(name, title) {
      this.newForm.netWork = title;
    },
    async handleCopy(copyText) {
      // if (!copyText) return Toast('Copy Fail')
      // this.$copyText(copyText).then((e) => {
      //   Toast('Copy successfully')
      // })
      let pasteStr = await navigator.clipboard.readText();
      this.newForm.hash = pasteStr;
    },
    // add
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            this.tabPages = r.data.data;
            this.useNetWorkList = this.tabPages.find(
              (_) => _.type === "usdt"
            )?.lists;
            this.newForm.netWork =
              this.useNetWorkList[this.useNetWorkActive].title;
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value;
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: "get",
        url: "pay/lists",
      });
      if (ret === 1) {
        this.tabPages = data;
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      const { hash, money } = this.newForm;
      const { id } = item;
      let params = {
        id,
        money,
        hash,
        account: this.account,
        isbefore: 1,
      };
      if (this.isTabBarLinkNotShowShopMoney) {
        params.third_id =
          this.rechargeType !== "third"
            ? Number(this.goodInfo.id) + 100000000
            : this.goodInfo.id;
      }

      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT(params)
        .then((r) => {
          Toast.clear();

          const { ret, msg } = r.data;

          if (!ret) return Toast(msg);

          // return this.$router.push("recharge-list");
          // return this.$router.push("thirdRechargeList");
          Dialog.alert({
            message: msg,
            confirmButtonText: this.$t("Button.confirm"),
            confirmButtonColor: "var(--theme)",
          }).then(() => {
            this.$router.push('/recharge-list')
          });
          return;

          if (r.data.ret === 2) {
            Toast.clear();
            window.location.href = r.data.data.url;
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf("qrcode") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "qrPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf("bank-BANK") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "bankPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("rechargeGoodDataInfo");
  },
  computed: {
    addCount() {
      return this.count;
    },
    ...mapGetters(["userInfo", "theme"]),
    account() {
      return this.userInfo.userphone || this.userInfo.email;
    }
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@Bc: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.wrap {
  min-height: 100vh;
  // background: #f6f8f9;
  background: @Bc;

  .bar {
    // background: #4f02dc;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--newBtn);
    border-radius: 50%;

    &:active {
      opacity: 0.8;
    }

    .iconfont {
      font-size: calc(40rem / 16);
    }
  }

  .userinfo_bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // background-image: url("../../assets/recharge/bg.png");
    background-color: var(--nav-background);
    background-size: 100% 100%;
    @tradeinfo_height: calc(90rem / 16);
    padding: calc(24rem / 16) calc(18rem / 16) calc(55rem / 16);
    margin-bottom: calc(15rem / 16 + @tradeinfo_height / 2);

    .nav_title {
      // color: #fff;
      color: @Color;
      // font-family: PingFangSC-Medium;
      font-size: calc(18rem / 16);
      margin-bottom: calc(18rem / 16);
    }

    .avatar {
      background-size: 100% 100%;
    }

    .level_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // color: #fff;
      color: @Color;
      margin-top: calc(14rem / 16);
      margin-bottom: calc(20rem / 16);
      // font-family: PingFangSC-Medium;
      font-size: calc(15rem / 16);

      .hr {
        width: 1px;
        height: calc(22rem / 16);
        // background: #fff;
        background: @Color;
        margin: 0 calc(16rem / 16);
      }
    }

    .record_bar {
      position: absolute;
      top: calc(10rem / 16);
      right: calc(10rem / 16);
      text-align: center;
      color: #fff;
      // background: #4f02dc;
      background: var(--theme);
      border-radius: 50%;

      &:active {
        opacity: 0.8;
      }

      .iconfont {
        font-size: calc(40rem / 16);
      }

      .text {
        font-size: calc(15rem / 16);
      }
    }

    .tradeinfo_bar {
      position: absolute;
      bottom: calc(@tradeinfo_height / 2 * -1);
      margin: 0 calc(13rem / 16);
      // background: #fff;
      background: @bg;
      border-radius: calc(10rem / 16);
      padding: 0 calc(25rem / 16);
      height: @tradeinfo_height;
      width: calc(100% - (13rem / 16) * 2 - (25rem / 16) * 2);

      >div {
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: calc(18rem / 16);

        // font-family: PingFangSC-Medium;
        .title {
          // color: #6e87a0;
          color: @Color;
        }

        .value {
          // color: #000;
          color: @sizeColor;
        }

        &:nth-child(1) {
          // border-bottom: 1px solid #000;
        }
      }
    }
  }

  .form_bar {
    margin: 0 calc(13rem / 16);
    padding: calc(13rem / 16);
    border-radius: calc(10rem / 16);
    // background: #0271dc;
    background: @bg;

    .submission_bar {
      @height: calc(60rem / 16);
      height: @height;
      // background: #fff;
      background: var(--nav-background);
      border-radius: calc(5rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc(10rem / 16);

      .title {
        font-size: calc(12rem / 16);
        // color: #6e87a0;
        // font-family: PingFangSC-Medium;
        white-space: nowrap;
        color: @Color;
      }

      >.input {
        margin-left: calc(10rem / 16);
        flex-grow: 1;
        overflow: hidden;
        // border-bottom: 1px solid #0271dc;
        border-bottom: 1px solid @Color;
        display: flex;
        // align-items: center;
        align-items: flex-end;

        >input {
          border: none;
          height: calc(@height * 0.5);
          // color: #0271dc;
          color: @Color;
          background: transparent;
        }
      }
    }

    .type_bar {
      padding: calc(15rem / 16) 0;
      display: flex;
      justify-content: space-between;

      >div {
        line-height: calc(35rem / 16);
        text-align: center;
        // color: #fff;
        color: @sizeColor;
        // font-family: PingFangSC-Medium;
        font-size: calc(12rem / 16);
        font-weight: normal;
        white-space: nowrap;
        padding: 0 calc(12rem / 16);
        border-radius: calc(5rem / 16);
      }

      .active {
        // background: #fff;
        background: var(--nav-background);
        color: @Color;
        // color: #0271dc;
      }
    }

    .textarea_wrap {
      position: relative;
      padding: calc(13rem / 16);
      // background: #fff;
      background: @bg;
      border-radius: calc(5rem / 16);
      border: 2px solid @Bc;

      .hash_bar {
        // background: #fff;
        height: calc(125rem / 16);
        border: none;
        width: 100%;
        background-color: @bg;
        color: @Color;
      }

      .btn_past {
        border-radius: 50%;
        @size: calc(50rem / 16);
        width: @size;
        line-height: @size;
        text-align: center;
        // background-color: #5a00ff;
        background-color: var(--newBtn);
        // color: #fff;
        color: var(--btnsizeColor);
        // color: @Bc;
        position: absolute;
        bottom: calc(13rem / 16);
        right: calc(13rem / 16);

        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  .submit_bar {
    margin: calc(33rem / 16) calc(13rem / 16);
    text-align: center;
    // background: #0271dc;
    background: var(--newBtn);
    line-height: calc(45rem / 16);
    // color: #fff;
    color: var(--btnsizeColor);
    border-radius: calc(5rem / 16);
  }
}

// .recharge {
//   position: relative;
//   font-family: "PingFang-Regular";
//   height: 100%;
//   /deep/ .van-tabs__line {
//     background-color: @Color;
//   }
//   .goodInfo_wrap {
//     margin: 6px 0;
//     padding: 0 10px;
//     display: flex;
//     .good_pic {
//       width: 80px;
//       height: 80px;
//     }
//     .other_info {
//       margin-left: 20px;
//     }
//   }
//   .van-nav-bar {
//     background-color: @Color;
//     background-image: var(--nav-background);

//     /deep/ .van-nav-bar__content {
//       // background-color: @Color;
//     }
//     /deep/.van-nav-bar__title {
//       color: #fff;
//     }
//     button {
//       background-color: @Color;
//       border: none;
//       color: #fff;
//       font-size: calc(10rem / 16);
//     }
//     .iconfont {
//       font-size: calc(22rem / 16);
//       color: #fff;
//     }
//   }

//   .van-field {
//     margin: calc(6rem / 16) 0;
//     /deep/.van-field__label {
//       width: calc(177rem / 16) !important;
//     }
//     /deep/.van-field__body {
//       input {
//         text-align: center;
//       }
//     }
//   }
//   .number {
//     /deep/.van-field__body {
//       input {
//         text-align: center;
//         color: @Color;
//       }
//     }
//   }

//   .grid {
//     margin: calc(7.5rem / 16) 0;
//     .grid_item {
//       /deep/ .van-grid-item__content {
//         padding: 0;
//         div {
//           width: 100%;
//           height: calc(41rem / 16);
//           font-size: calc(13rem / 16);
//           line-height: calc(45rem / 16);
//           text-align: center;
//           color: @Color;
//           background-color: #ffffff;
//         }
//         .active {
//           color: #ffffff;
//           background-color: @Color;
//         }
//       }
//     }
//   }

//   .van-cell {
//     background-color: transparent;
//   }
//   .list-title-text {
//     font-size: calc(13rem / 16);
//   }
//   .list-title-rtext {
//     color: #a87cf3;
//     font-size: calc(13rem / 16);
//     text-decoration: underline;
//   }

//   .tabs {
//     .mode {
//       position: relative;
//       display: flex;
//       box-sizing: border-box;
//       width: 100%;
//       margin-top: calc(7.5rem / 16);
//       padding: calc(7.5rem / 16);
//       background-color: #fff;
//     }
//     .step {
//       font-size: calc(12rem / 16);
//       h5 {
//         margin: 0 0 calc(7.5rem / 16) 0;
//       }
//       .desc_wrap {
//         display: flex;
//         justify-content: space-between;
//         img {
//           height: 30px;
//           min-width: 30px;
//           margin-right: 20px;
//         }
//       }
//       p {
//         width: 80%;
//       }
//     }
//     .tabbar-letter {
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: calc(32.5rem / 16);
//       height: calc(32.5rem / 16);
//     }
//     .step-image {
//       width: calc(50rem / 16);
//       height: calc(50rem / 16);
//       margin-right: calc(10rem / 16);
//     }
//     .tips {
//       text-align: center;
//       margin-top: calc(45rem / 16);
//       font-size: calc(10rem / 16);
//     }
//   }

//   .tutorial {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 999;
//     height: 100%;

//     img {
//       height: 100%;
//       width: 100%;
//     }
//   }
// }
// .backimg {
//   width: 20px;
//   height: 20px;
// }
// .commit-btn {
//   width: 85%;
//   height: calc(44rem / 16);
//   font-size: calc(15rem / 16);
//   background: @Bc;
//   color: #fff;
//   border: none;
//   padding: calc(10rem / 16) calc(26rem / 16);
//   display: block;
//   margin: calc(40rem / 16) auto 0;
// }

// /deep/ .van-button {
//   background: var(--nav-background) !important;
//   border: none;
//   color: #fff;
//   font-size: calc(15rem / 16);
// }
// .textarea_wrap {
//   width: 90%;
//   margin: 20px auto;
//   // background: #f5f5f5;
//   border-radius: 6px;
//   position: relative;
//   border: 1px solid #f5f5f5;
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

//   .textarea {
//     border-radius: 6px;
//   }
//   /deep/ .van-cell {
//     &::after {
//       border: none;
//     }
//   }

//   /deep/ textarea {
//     // color: #4f5860;
//     color: var(--theme);
//   }
//   .btn {
//     position: absolute;
//     right: calc(8rem / 16);
//     bottom: calc(8rem / 16);
//   }
// }</style>
